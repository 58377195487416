<template>
    <div v-if="this.pageData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="about" />
        <main>
            <section class="green-banner" v-if="this.pageData.attributes.field_description_w_summary.summary">
                <div class="container"> <p> {{ this.pageData.attributes.field_description_w_summary.summary }} </p> </div>
            </section>
            <section class="main-content">
                <div class="container">
                    <p v-html="this.pageData.attributes.field_description_w_summary.value">
                    </p>
                    <div class="attachments-container">
                        <attachments v-if="pageData" :attachmentData="pageData"></attachments>
                    </div>
                </div>
            </section>

            <section class="objectives">
                <div class="container">
                    <div class="title">
                        <img src="../assets/icon-objectives.svg" alt="">
                        <h2>
                            Specific objectives of the initiative are:
                        </h2>
                    </div>
                    <div class="boxes" v-if="this.pageData.field_ref_ui_text">
                        <div v-for="(t, index) in this.pageData.field_ref_ui_text.slice(0,this.pageData.field_ref_ui_text.length-1)" :key="'obj'+index">
                            <h3>
                                {{ '0' + (index+1) + '.'}}
                            </h3>
                            <p v-html="t.attributes.field_description_w_summary.value">
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="main-content">
                <div class="container">
                    <keep-alive v-if="this.pageData.field_ref_ui_text.length">
                        <p v-html="this.pageData.field_ref_ui_text[this.pageData.field_ref_ui_text.length-1].attributes.field_description_w_summary.value"></p>
                    </keep-alive>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import Attachments from '../components/Attachments.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchSinglePage } from '../libs/drupalClient'
    export default {
        name: 'aims-and-objectives',
        components: {
            HeaderIntroImage,
            Attachments
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "643d228f-e8e6-44ec-a0c9-d746966ea016"
            }
        },
        computed: {},
        methods: {},
        mounted() {
            fetchSinglePage(this.currentPageID, {
                include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text', 'field_attachments']
            })
            .then(res => this.pageData = res )
        }
    }
</script>

<style lang="scss" scoped>
</style>